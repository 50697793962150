import { NextSeo } from 'next-seo';
import { Fragment } from 'react';
import { Box, LoginBox, STATEMINTS_APP_NAME } from 'ui';
export default function LoginPage() {
  return <Fragment>
            <NextSeo title="Statemints - Login" data-sentry-element="NextSeo" data-sentry-source-file="login.tsx" />
            <Box bg="dark" padding="sm" bRadius="1" alignItems="center" css={{
      width: '95%',
      maxWidth: '650rem',
      m: '0 auto',
      position: 'fixed',
      top: '50%',
      right: 0,
      left: 0,
      transform: 'translateY(-50%)',
      '@md': {
        padding: '40rem 60rem'
      }
    }} boxShadowed data-sentry-element="Box" data-sentry-source-file="login.tsx">
                <LoginBox appName={STATEMINTS_APP_NAME} data-sentry-element="LoginBox" data-sentry-source-file="login.tsx" />
            </Box>
        </Fragment>;
}